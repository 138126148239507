/*!
* Start Bootstrap - Agency v3.3.7+1 (http://startbootstrap.com/template-overviews/agency)
* Copyright 2013-2016 Start Bootstrap
* Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
*/
body {
    overflow-x: hidden;
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-muted {
    color: #777777;
}
.text-primary {
    color: #fed136;
}
p {
    font-size: 14px;
    line-height: 1.75;
}
p.large {
    font-size: 16px;
}
a,
a:hover,
a:focus,
a:active,
a.active {
    outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
}
.img-centered {
    margin: 0 auto;
}
.bg-light-gray {
    background-color: #eeeeee;
}
.bg-darkest-gray {
    background-color: #222222;
}
.btn-primary {
    color: white;
    background-color: #fed136;
    border-color: #fed136;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    color: white;
    background-color: #fec503;
    border-color: #f6bf01;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #fed136;
    border-color: #fed136;
}
.btn-primary .badge {
    color: #fed136;
    background-color: white;
}
.btn-xl {
    color: white;
    background-color: #fed136;
    border-color: #fed136;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 3px;
    font-size: 18px;
    padding: 20px 40px;
}
.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    color: white;
    background-color: #fec503;
    border-color: #f6bf01;
}
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none;
}
.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    background-color: #fed136;
    border-color: #fed136;
}
.btn-xl .badge {
    color: #fed136;
    background-color: white;
}
.navbar-custom {
    background-color: #222222;
    border-color: transparent;
}
.navbar-custom .navbar-brand {
    margin: 10px 0;
    color: #fed136;
    font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}
.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus,
.navbar-custom .navbar-brand:active,
.navbar-custom .navbar-brand.active {
    color: #fec503;
}
.navbar-custom .navbar-collapse {
    border-color: rgba(255, 255, 255, 0.02);
}
.navbar-custom .navbar-toggle {
    background-color: #fed136;
    border-color: #fed136;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
}
.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
    background-color: #fed136;
}

.navbar-custom .nav li a {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: #000000;
}
.navbar-custom.affix .nav li a {
    color: #FFFFFF;
}
@media (max-width: 767px) {
    .owl-carousel {
        margin-top: 70px;
    }
    section h2.section-heading {
        font-size: 30px;
    }
    .navbar-custom .nav li a {
        color: #FFFFFF;
    }
}
.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus {
    color: #FFFFFF;
    outline: none;
}
.navbar-nav {
    margin-top: 10px;
}
.navbar-custom .navbar-nav > .active > a {
    border-radius: 0;
    color: white;
    background-color: #222222;
}
.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus {
    color: white;
    background-color: #222222;
}
@media (min-width: 768px) {
    .navbar-custom {
        background-color: transparent;
        padding: 25px 0;
        -webkit-transition: padding 0.3s;
        -moz-transition: padding 0.3s;
        transition: padding 0.3s;
        border: none;
    }
    .navbar-custom .navbar-brand {
        font-size: 2em;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
    }
    .navbar-custom .navbar-nav > .active > a {
        border-radius: 3px;
    }
}
@media (min-width: 768px) {
    .navbar-custom.affix {
        background-color: #222222;
        padding: 10px 0;
    }
    .navbar-custom.affix .navbar-brand {
        font-size: 1.5em;
    }
}
header {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    /*text-align: center;*/
    color: white;
}
header .intro-text {
    padding-top: 100px;
    padding-bottom: 50px;
}
header .intro-text .intro-card {
    width: 50vh;
}
header .intro-text .intro-lead-in {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 5vh;
    line-height: 22px;
    margin-bottom: 25px;
}
header .intro-text .intro-heading {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25px;
}
@media (min-width: 768px) {
    header .intro-text {
        padding-top: 250px;
        padding-bottom: 100px;
    }
    header .intro-text .intro-lead-in {
        font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: italic;
        font-size: 5vh;
        line-height: 40px;
        margin-bottom: 25px;
    }
    header .intro-text .intro-heading {
        font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 75px;
        line-height: 75px;
        margin-bottom: 50px;
    }
}
section {
    padding: 100px 0;
}
section h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
}
section h3.section-subheading {
    font-size: 16px;
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: none;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 75px;
}
@media (min-width: 768px) {
    section {
        padding: 50px 0;
    }
}
.step {
    width: 200px;
    margin: 50px auto 0;
}
.step img {
    width: 100px;
}
.step-heading {
    margin: 15px 0;
    color: #333333;
    text-transform: none;
}
#start {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
.start {
    text-align: center;
    padding-bottom: 20px;
}
.start img {
    width: 300px;
    height: auto;
}
.start .start-text {
    padding: 20px;
    font-size: 16px;
}
@media (min-width: 768px) {
    .start img {
        width: 400px;
        height: auto;
    }
    .start .start-text {
        padding: 20px 100px;
    }
}
@media (min-width: 992px) {
    .start .start-text {
        padding: 20px 200px;
    }
}
#card-section .header {
    text-align: center;
    font-size: 40px;
    padding-bottom: 60px;
}
.nav-tabs > li > a {
    text-align: center;
    font-size: 20px;
    border-radius: 0 !important;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
    background-color: #ffc107;
    border-right: 0;
}
.nav-tabs > li.active::before {
    display: table;
    content: " ";
    right: -28px;
    z-index: 10;
    width: 0;
    position: absolute;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 30px solid #ffc107;
}
.list-card {

}
.item-card {
    display: inline-block;
    list-style: none;
    padding: 0;
}
.list-card .item-card img {
    width: 350px;
    padding: 20px;
}
.list-category {
    padding: 0;
    list-style: none;
}
.item-category {
    display: inline-block;
    padding: 10px;
}
.item-category img {
    width: 100%;
}
@media (min-width: 768px) {
    .item-category {
        width: 250px;
    }
}
@media (min-width: 992px) {
    .item-category {
        width: 250px;
    }
}
@media (min-width: 1200px) {
    .list-category {
        text-align: center;
    }
    .item-category {
        width: 300px;
    }
}

.list-product {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 240px;
    padding: 20px 0;
}
.item-product {
    padding: 10px;
    width: 270px;
}
.item-product label {
    cursor: pointer;
    height: 150px;
    width: 240px;
    position: relative;
}
.item-product img {
    width: 100%;
}
.item-product p {
    padding: 0;
    margin: 0;
}

.item-product input[type=radio] {
    display: none;
}

.list-product img {
    cursor: pointer;
    height: 150px;
    width: 240px;
    border: 0;
    border-radius: 8px !important;
}

.item-product.selected label {
    position: relative;
}

.item-category a {
    position: relative;
    display: block;
}

.item-category a:before,
.item-product label:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 3.5;
    border-radius: 8px;
    transition: all .2s;
}

.item-category a:hover:before,
.item-product label:hover:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    width: 100%;
    line-height: 3.5em;
    border: 3px solid #FFEB3B;
    border-radius: 8px;
    transition: all .2s;
}

.item-product.selected label:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    content: "\f00c";
    position: absolute;
    color: #FFEB3B;
    background-color: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    line-height: 3.5em;
    border: 3px solid #FFEB3B;
}

footer {
    padding: 20px 0;
    text-align: center;
    height: 150px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: bottom right;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
    -o-background-size: contain;
}
footer .content {
    height: 50px;
}
@media (min-width: 1200px) {
    footer .content {
        margin-top: 50px;
    }
}
footer span.copyright {
    line-height: 40px;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    text-transform: none;
}
footer ul.quicklinks {
    margin-bottom: 0;
    line-height: 40px;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    text-transform: none;
}
ul.social-buttons {
    margin-bottom: 0;
}
ul.social-buttons li a {
    display: block;
    background-color: #222222;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    color: white;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
    background-color: #fed136;
}
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: none;
}
::-moz-selection {
    text-shadow: none;
    background: #fed136;
}
::selection {
    text-shadow: none;
    background: #fed136;
}
img::selection {
    background: transparent;
}
img::-moz-selection {
    background: transparent;
}
.features {
    list-style: none;
    color: #777;
}
body {
    webkit-tap-highlight-color: #fed136;
}
.navbar-fixed-top .navbar-header .navbar-brand img {
    margin-top: -20px;
}
.navbar-fixed-top.affix-top .navbar-header .navbar-brand .logo {
    display: block;
}
.navbar-fixed-top.affix-top .navbar-header .navbar-brand .logo-white {
    display: none;
}
.navbar-fixed-top.affix .navbar-header .navbar-brand .logo {
    display: none;
}
.navbar-fixed-top.affix .navbar-header .navbar-brand .logo-white {
    display: block;
}
.new-card-holder {
    padding: 20px 40px;
}
@media (max-width: 375px) {
    .new-card-holder {
        padding: 20px;
        font-size: 14px;
    }
}
.quicklinks a {
    color: #000000;
}
.item-category p {
    margin-bottom: 0;
}
.title-or {
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0 0 20px;
}
